import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ContactButton from 'app/components/ContactButton';
import ContentTitle from 'app/components/ContentTitle';

const Root = styled.div`
    background-color: var(--clr-white);
    border-radius: 1rem;
    overflow: hidden;
    padding: 3rem 2rem;
    position: relative;

    &::before {
        background-color: var(--clr-theme-2);
        content: '';
        height: 1rem;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    @media (min-width: 800px) {
        background: var(--clr-white) url('/images/calltoaction-bg.svg') no-repeat 100% 100%;
        background-size: contain;
        padding: 6rem;
        padding-right: 45%;
    }
`;

const Content = styled.p`
    margin-bottom: 4rem;
`;

const CallToAction = () => (
    <Root>
        <ContentTitle>Interested?</ContentTitle>
        <Content>
            So, if your child aspires to the stage and musical theatre contact iPerform where we are
            building the performers of the future!
        </Content>
        <ContactButton />
    </Root>
);

CallToAction.propTypes = {};

CallToAction.defaultProps = {};

export default CallToAction;
