import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import AssistiveTitle from 'app/components/AssistiveTitle';
import Container from 'app/components/Container';
import TestimonialList from 'app/components/TestimonialList';

const Root = styled.section`
    background: var(--clr-grey-light) url('/images/testimonial-bg.svg') no-repeat 50% 100%;
    background-size: cover;
    color: var(--clr-white);
    padding: 8rem 0 12rem;
    position: relative;
    z-index: 1;

    @media (min-width: 500px) {
        padding: 20rem 0 30rem;
    }
`;

const Inner = styled.div`
    position: relative;

    &::before {
        background: url('/images/logo-mark.svg') no-repeat center;
        background-size: contain;
        content: '';
        height: 14rem;
        left: 0;
        position: absolute;
        bottom: -15.5rem;
        transform: rotate(-15deg);
        width: 14rem;
    }

    @media (min-width: 500px) {
        &::before {
            bottom: -30rem;
            height: 26rem;
            width: 26rem;
        }
    }

    @media (min-width: 800px) {
        &::before {
            height: 32rem;
            width: 32rem;
        }
    }
`;

const TestimonialSection = () => (
    <Root>
        <Container>
            <Inner>
                <AssistiveTitle>Testimonials</AssistiveTitle>
                <TestimonialList />
            </Inner>
        </Container>
    </Root>
);

TestimonialSection.propTypes = {};

TestimonialSection.defaultProps = {};

export default TestimonialSection;
