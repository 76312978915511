import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TestimonialListItem from 'app/components/TestimonialListItem';

const data = [
    "My daughter loves it! Lovely people teaching and very organised, always know what's happening ahead of time!",
    'My daughter has been going since September and absolutely loves it and her confidence has grown immensely',
    'All the ladies are so lovely and at the end of the term they do a show which I watched at Christmas',
    'You could see how much hard work had gone into it and all the kids did amazing!',
    "Would definitely recommend this for anyone's little ones that love to sing and dance.",
    'Such a lovely family-like atmosphere, Grace loves it and felt welcome from day 1. Highly recommended.',
    'So much fun, Elsie loves it her confidence is growing every week.',
    'My son loves being in such a welcoming and friendly environment doing his favourite things of acting, singing and dancing.',
    "Betsy LOVES her Saturday mornings...'it's like a big family, everyone feels included!' In her words!",
];

const Root = styled.ul`
    display: grid;
    grid-template: 1 / 1;
`;

const Item = styled.li`
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    opacity: ${(props) => (props.isActive ? 1 : 0)};
    transform: ${(props) => (props.isActive ? 'translate3d(0, 0, 0)' : 'translate3d(-5rem, 0, 0)')};
    transition: opacity 0.3s, transform 0.3s;
`;

const TestimonialList = () => {
    const [itemIndex, setItemIndex] = useState(0);

    useEffect(() => {
        const itvl = setInterval(() => {
            setItemIndex(itemIndex === data.length - 1 ? 0 : itemIndex + 1);
        }, 8000);

        return () => {
            clearInterval(itvl);
        };
    }, [itemIndex]);

    return (
        <Root>
            {data.map((item, index) => (
                <Item key={item} isActive={index === itemIndex}>
                    <TestimonialListItem>{item}</TestimonialListItem>
                </Item>
            ))}
        </Root>
    );
};

TestimonialList.propTypes = {};

TestimonialList.defaultProps = {};

export default TestimonialList;
