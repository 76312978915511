import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.blockquote`
    font-family: var(--ff-title);
    font-size: var(--fs-lrg);
    line-height: var(--lh-title);
    position: relative;
    text-align: right;

    &::before {
        background: url('/images/quote-bg.svg') no-repeat 100% 0;
        background-size: contain;
        bottom: -4rem;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: -4rem;
    }

    @media (min-width: 500px) {
        font-size: var(--fs-3xlrg);
    }
`;

const TestimonialListItem = ({ children }) => <Root>{children}</Root>;

TestimonialListItem.propTypes = {};

TestimonialListItem.defaultProps = {};

export default TestimonialListItem;
