import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.div`
    margin-bottom: 3rem;
    padding-top: 12rem;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        background: url('/images/summary.svg') no-repeat 50% 0;
        background-size: 100%;
        content: '';
        height: 10.5rem;
        left: calc(50% - 5rem);
        position: absolute;
        top: 0;
        width: 10rem;
    }

    &:nth-child(2)::before {
        background-position-y: 33.33%;
    }

    &:nth-child(3)::before {
        background-position-y: 66.67%;
    }

    &:nth-child(4)::before {
        background-position-y: 100%;
    }

    @media (min-width: 500px) {
        width: 50%;

        &:nth-last-child(1),
        &:nth-last-child(2) {
            margin-bottom: 0;
        }
    }

    @media (min-width: 800px) {
        margin-bottom: 0;
        width: auto;
    }
`;

const Term = styled.dt`
    font-weight: var(--fw-bold);
    letter-spacing: 0.1em;
    text-transform: uppercase;
`;

const Def = styled.dd`
    a {
        color: var(--clr-white);
        position: relative;

        &::before {
            border-bottom: 0.1rem var(--clr-white) dashed;
            bottom: -0.4rem;
            content: '';
            height: 0;
            left: 0;
            position: absolute;
            right: 0;
        }
    }
`;

const SummaryListItem = ({ def, term, link }) => (
    <Root>
        <Term>{term}</Term>
        <Def>
            {link ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {def}
                </a>
            ) : (
                def
            )}
        </Def>
    </Root>
);

SummaryListItem.propTypes = {};

SummaryListItem.defaultProps = {};

export default SummaryListItem;
