import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ContactButton from 'app/components/ContactButton';
import Container from 'app/components/Container';

const Root = styled.header`
    background: url('/images/hero-bg.svg') no-repeat 50% 100%;
    background-size: cover;
    color: var(--clr-white);
    padding: 12rem 0;

    @media (min-width: 500px) {
        padding: 16rem 0 24rem;
    }

    @media (min-width: 700px) {
        padding: 24rem 0;
    }

    @media (min-width: 800px) {
        overflow: hidden;
    }
`;

const Title = styled.h1`
    font-family: var(--ff-title);
    font-size: var(--fs-xlrg);
    line-height: var(--lh-title);
    margin-bottom: 4rem;
    max-width: 66rem;

    strong {
        font-weight: inherit;

        &:nth-child(1) {
            color: var(--clr-theme-3);
        }

        &:nth-child(2) {
            color: var(--clr-theme-1);
        }
    }

    @media (min-width: 500px) {
        font-size: var(--fs-3xlrg);
    }
`;

const Inner = styled.div`
    position: relative;

    &::before {
        background: url('/images/logo-mark.svg') no-repeat center;
        background-size: contain;
        bottom: -16rem;
        content: '';
        height: 16rem;
        position: absolute;
        right: 5%;
        transform: rotate(-15deg);
        width: 16rem;
    }

    @media (min-width: 500px) {
        &::before {
            bottom: -28rem;
            height: 30rem;
            width: 30rem;
        }
    }

    @media (min-width: 800px) {
        &::before {
            bottom: -20rem;
            height: 50rem;
            left: 62rem;
            right: auto;
            width: 50rem;
        }
    }
`;

const Hero = () => (
    <Root>
        <Container>
            <Inner>
                <Title>
                    We help young people gain <strong>confidence</strong> through{' '}
                    <strong>performing arts</strong>
                </Title>
                <ContactButton />
            </Inner>
        </Container>
    </Root>
);

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
