import React from 'react';

import AgeGroupSection from 'app/components/AgeGroupSection';
import CallToActionSection from 'app/components/CallToActionSection';
import ContactSection from 'app/components/ContactSection';
import Hero from 'app/components/Hero';
import Introduction from 'app/components/Introduction';
import Scene from 'app/components/Scene';
import SummarySection from 'app/components/SummarySection';
import TestimonialSection from 'app/components/TestimonialSection';

const HomePage = () => (
    <Scene title="iPerform Academy of Theatre Arts">
        <Hero />
        <Introduction title="Who are iPerform?">
            <p>
                iPerform is a fabulous performance based theatre school, where children can have fun
                whilst learning.
            </p>
            <p>
                Students can make new friends in a fun, safe, and friendly environment, gaining
                confidence, as well as aiding their development, co-ordination, musicality and
                imagination.
            </p>
            <p>
                iPerform is the brain-child of, musical theatre professionals, Sara Foley and
                Victoria Hall, who both studied at the world renowned Italia Conti Academy of
                Performing Arts and have both had successful stage careers. After both settling down
                and bringing up a family, they decided to share their wealth of knowledge and so
                iPerform was born.
            </p>
            <p>
                All teachers have trained at some of the top schools and colleges and worked
                professionally in the West End, nationally and abroad and have had many years of
                teaching experience between them. All are DBS checked and insured.
            </p>
        </Introduction>
        <SummarySection />
        <AgeGroupSection />
        <TestimonialSection />
        <CallToActionSection />
    </Scene>
);

export default HomePage;
