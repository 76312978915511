import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CallToAction from 'app/components/CallToAction';
import Container from 'app/components/Container';

const Root = styled.section`
    background-color: var(--clr-grey-light);
    padding: 6rem 0;
    position: relative;

    @media (min-width: 800px) {
        padding: 12rem 0;
    }
`;

const CallToActionSection = () => (
    <Root>
        <Container isLimited>
            <CallToAction />
        </Container>
    </Root>
);

CallToActionSection.propTypes = {};

CallToActionSection.defaultProps = {};

export default CallToActionSection;
