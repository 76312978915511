import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import AgeGroupListItem from 'app/components/AgeGroupListItem';
import Container from 'app/components/Container';

const Root = styled.div`
    position: relative;

    &::before {
        background-color: var(--clr-dark);
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 50%;
    }
`;

const data = [
    {
        theme: 1,
        title: 'Juniors',
        subtitle: '4 to 6 years old',
        description:
            'Introducing young children to performing arts and allowing them to develop creative passions whilst simultaneously teaching children language and communication skills. Helping them to communicate effectively with others with confidence.',
    },
    {
        theme: 2,
        title: 'Inters',
        subtitle: '7 to 10 years old',
        description:
            'Performing Arts allows children to be playful and creative. To explore their curiosity and try out new things, and new ways of thinking or behaving. Children learn to trust others and build relationships as they learn to share their thoughts and ideas with confidence.',
    },
    {
        theme: 3,
        title: 'Seniors',
        subtitle: '11 to 16 years old',
        description:
            'Performing Arts are all about self expression, exploring alternative options and embracing individuality.  With improved self confidence and self belief, comes a natural pathway for children to go on to master many more skills that will equip them for life.',
    },
];

const AgeGroupList = () => (
    <Root>
        <Container isLimited>
            {data.map((item) => (
                <AgeGroupListItem key={item.title} {...item} />
            ))}
        </Container>
    </Root>
);

AgeGroupList.propTypes = {};

AgeGroupList.defaultProps = {};

export default AgeGroupList;
