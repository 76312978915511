import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.article`
    background-color: ${(props) => `var(--clr-theme-${props.theme})`};
    border-radius: 1rem;
    color: var(--clr-white);
    margin-bottom: 1rem;
    padding: 2rem;
    position: relative;
    text-align: left;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 800px) {
        align-items: center;
        display: flex;
        padding: 3rem 6rem 3rem 0;
    }
`;

const Header = styled.header`
    margin-bottom: 2rem;
    min-width: 30%;
    text-align: center;

    @media (min-width: 800px) {
        margin-bottom: 0;
    }
`;

const Title = styled.h4`
    font-family: var(--ff-title);
    font-size: var(--fs-lrg);
    line-height: var(--lh-title);

    @media (min-width: 500px) {
        font-size: var(--fs-xlrg);
    }
`;

const Subtitle = styled.small`
    display: block;
    font-size: var(--fs-sml);
    line-height: var(--lh-title);

    @media (min-width: 500px) {
        font-size: var(--fs-med);
    }
`;

const Description = styled.div``;

const AgeGroupListItem = ({ description, subtitle, theme, title }) => (
    <Root theme={theme}>
        <Header>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </Header>
        <Description>{description}</Description>
    </Root>
);

AgeGroupListItem.propTypes = {};

AgeGroupListItem.defaultProps = {};

export default AgeGroupListItem;
