import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SummaryListItem from 'app/components/SummaryListItem';

const data = [
    {
        term: 'Location',
        def: 'Kesgrave, Suffolk',
        link:
            'https://www.google.com/maps/@52.0615546,1.234197,17z/data=!3m1!4b1!4m5!3m4!1s0x47d99e8b47a56203:0xc1efc226540828b3!8m2!3d52.0615546!4d1.2363857',
    },
    {
        term: 'Date & Time',
        def: 'Saturdays, 9am - 12pm',
    },
    {
        term: 'For Ages',
        def: '4 - 16 years old',
    },
    {
        term: 'Skills',
        def: 'Dancing, Singing & Acting',
    },
];

const Root = styled.dl`
    background-color: var(--clr-dark);
    border-radius: 1rem;
    color: white;
    font-size: var(--fs-sml);
    padding: 4rem;
    position: relative;
    text-align: center;

    @media (min-width: 500px) {
        display: flex;
        flex-wrap: wrap;
    }

    @media (min-width: 800px) {
        align-items: center;
        height: 24rem;
        justify-content: space-around;
        padding: 0;
    }
`;

const SummaryList = () => (
    <Root>
        {data.map((item) => (
            <SummaryListItem key={item.term} {...item} />
        ))}
    </Root>
);

SummaryList.propTypes = {};

SummaryList.defaultProps = {};

export default SummaryList;
