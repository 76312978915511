import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import AgeGroupList from 'app/components/AgeGroupList';
import ContactButton from 'app/components/ContactButton';
import Container from 'app/components/Container';
import ContentTitle from 'app/components/ContentTitle';

const Root = styled.section`
    background-color: var(--clr-grey-light);
    margin-top: -12rem;
    padding-top: 20rem;
    text-align: center;
`;

const Content = styled.div`
    margin-bottom: 8rem;

    p {
        margin-bottom: 4rem;
    }
`;

const AgeGroupSection = () => (
    <Root>
        <Container isLimited>
            <ContentTitle isCentered>What do we do?</ContentTitle>
            <Content>
                <p>
                    All classes will be musical theatre based working towards end of term, in house
                    performances as well as external shows in the West End, at some of the most
                    prestigious theatres in London. We are very much a performance based school also
                    concentrating on confidence building.
                </p>
                <ContactButton />
            </Content>
        </Container>
        <AgeGroupList />
    </Root>
);

AgeGroupSection.propTypes = {};

AgeGroupSection.defaultProps = {};

export default AgeGroupSection;
