import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.h3`
    height: 0.1rem;
    overflow: hidden;
    position: absolute;
    text-indent: 0.1rem;
    white-space: nowrap;
    width: 0.1rem;
`;

const AssistiveTitle = ({ children }) => <Root>{children}</Root>;

AssistiveTitle.propTypes = {};

AssistiveTitle.defaultProps = {};

export default AssistiveTitle;
