import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from 'app/components/Container';
import SummaryList from 'app/components/SummaryList';

const Root = styled.section``;

const SummarySection = () => (
    <Root>
        <Container>
            <SummaryList />
        </Container>
    </Root>
);

SummarySection.propTypes = {};

SummarySection.defaultProps = {};

export default SummarySection;
